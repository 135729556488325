<template>
  <div class="home-container">
    <header class="home-header">
      <h1 class="home-heading">
        <span class="home-text">逍</span>
        <br class="home-text01" />
        <span class="home-text02">遥</span>
        <br class="home-text03" />
        <span class="home-text04">岛</span>
        <br class="home-text05" />
        <span class="home-text06">D</span>
        <br class="home-text07" />
        <span class="home-text08">A</span>
        <br class="home-text09" />
        <span class="home-text10">O</span>
        <br />
      </h1>
      <div class="home-container01"></div>
      <img
        alt="image"
        src="/yingyi_large_assortment_of_different_galaxies_with_a_red_star_i_f52d3f25-a5bf-4b63-a6e1-b03cbd325560-1500w.png"
        class="home-image"
      />
      <div class="home-container02">
        <h1 class="home-text12">
          <span>入住逍遥岛</span>
          <br />
          <span>开启产品共创和逍遥人生</span>
          <br />
        </h1>
        <span class="home-text17">大鹏一日同风起，扶摇直上九万里</span>
        <a
          href="https://discord.gg/UWFkfNXD"
          target="_blank"
          rel="noreferrer noopener"
          class="home-link button"
        >
          即刻登岛 Discord
        </a>
      </div>
      <div class="home-container03">
        <a
          href="https://discord.gg/7SS9qDPrBB"
          target="_blank"
          rel="noreferrer noopener"
          class="home-link1"
        >
          <img
            alt="image"
            src="/discord-fill-svgrepo-com.svg"
            class="home-discord-white"
          />
        </a>
        <a
          href="https://github.com/yingyiverse"
          target="_blank"
          rel="noreferrer noopener"
          class="home-link2"
        >
          <img
            alt="image"
            src="/github-142-svgrepo-com%20(1).svg"
            class="home-github-white"
          />
        </a>
      </div>
    </header>
    <div class="home-us">
      <div class="home-container04">
        <div class="home-container05">
          <h6 class="home-text18">Who we are</h6>
          <h3 class="home-text19">我们是谁</h3>
          <span class="home-text20">
            <span>
              逍遥岛（DAO）是一群热爱自由生活、对世界充满热爱和好奇的人组成的去中心化组织，我们来自不同的背景、不同的行业，因为认同「
            </span>
            <span class="home-text22">逍遥游</span>
            <span>」精神和「</span>
            <span class="home-text24">DAO</span>
            <span>
              」精神相聚。在这里，我们根据各自的兴趣组队推进项目，一起创造，一起成长。
            </span>
            <br />
          </span>
        </div>
        <div class="home-container06">
          <h6 class="home-text27">projects</h6>
          <h3 class="home-text28">我们的项目</h3>
          <span class="home-text29">
            推进中的项目包括X-Copilot、照见清心、以及42等。
          </span>
        </div>
        <div class="home-cards-container">
          <div class="home-card1">
            <div class="home-container07">
              <svg viewBox="0 0 1024 1024" class="home-icon">
                <path
                  d="M639.403 658.091l32 241.152-137.429-82.475c-13.269-7.851-29.995-8.363-43.904 0l-137.429 82.475 32.043-241.109c39.296 15.829 82.304 24.533 127.317 24.533s88.021-8.747 127.403-24.576zM654.165 554.283c-2.475 1.28-4.821 2.773-6.955 4.48-39.253 24.448-85.547 38.571-135.211 38.571-70.699 0-134.656-28.587-181.035-74.965s-74.965-110.336-74.965-181.035 28.587-134.656 74.965-181.035 110.336-74.965 181.035-74.965 134.656 28.587 181.035 74.965 74.965 110.336 74.965 181.035-28.587 134.656-74.965 181.035c-11.861 11.861-24.875 22.571-38.869 31.915zM304.64 612.48l-48.256 363.221c-3.115 23.339 13.312 44.8 36.693 47.915 9.984 1.323 19.669-0.939 27.563-5.717l191.36-114.816 191.403 114.816c20.224 12.117 46.421 5.589 58.539-14.635 5.205-8.661 6.955-18.389 5.717-27.563l-48.213-363.307c11.947-9.173 23.296-19.115 33.92-29.739 61.696-61.696 99.968-147.072 99.968-241.323s-38.272-179.627-99.968-241.365-147.115-99.968-241.365-99.968-179.627 38.272-241.365 99.968-99.968 147.115-99.968 241.365 38.272 179.627 99.968 241.365c10.667 10.667 22.016 20.608 33.963 29.781z"
                ></path>
              </svg>
            </div>
            <h6 class="home-text30 TextXL">X-Copilot</h6>
            <span class="home-text31">
              基于AGI的超级个人助理，下一代个人信息助手，在碎片化的时代寻找熵减。
            </span>
          </div>
          <div class="home-card2">
            <div class="home-container08">
              <svg viewBox="0 0 1024 1024" class="home-icon2">
                <path
                  d="M768 639.968l-182.82-182.822 438.82-329.15-128.010-127.996-548.52 219.442-172.7-172.706c-49.78-49.778-119.302-61.706-154.502-26.508-35.198 35.198-23.268 104.726 26.51 154.5l172.686 172.684-219.464 548.582 127.99 128.006 329.19-438.868 182.826 182.828v255.98h127.994l63.992-191.988 191.988-63.996v-127.992l-255.98 0.004z"
                ></path>
              </svg>
            </div>
            <h6 class="home-text32 TextXL">照见清心</h6>
            <span class="home-text33">
              <span class="home-text34">
                用旅行的方式，融入禅修，亲近自然，在一段旅行中遇见真实的自己。
              </span>
              <br class="home-text35" />
            </span>
          </div>
          <div class="home-card3">
            <div class="home-container09">
              <svg viewBox="0 0 1024 1024" class="home-icon4">
                <path
                  d="M636 938h-6q-92-24-158-90-38-38-65-103t-27-119q0-52 38-89t92-37 93 37 39 89q0 34 25 58t63 24 64-24 26-58q0-120-91-206t-219-86q-92 0-168 47t-114 125q-24 50-24 120 0 80 28 154 6 20-14 26t-26-12q-32-82-32-168 0-78 30-138 42-90 129-144t191-54q146 0 249 99t103 237q0 52-39 88t-93 36-92-36-38-88q0-34-26-59t-64-25-63 25-25 59q0 112 80 192 56 56 140 78 18 2 14 26-4 16-20 16zM530 626q0 74 55 128t137 54q4 0 18-2t23-2 18 3 11 13q4 22-18 26-24 4-52 4-80 0-132-38-102-70-102-186 0-22 22-22 20 0 20 22zM416 930q-8 0-14-6-54-54-86-114-46-80-46-184 0-94 71-162t171-68 171 68 71 162q0 20-22 20t-22-20q0-78-58-133t-140-55-140 55-58 133q0 96 38 164 26 46 80 104 16 14 0 30-6 6-16 6zM150 414q-22 0-22-20 0-4 4-12 64-92 160-140 100-52 220-52t220 52q98 48 160 138 4 8 4 12 0 14-16 20t-24-8q-60-82-144-124-92-46-200-47t-200 47q-90 46-146 126-6 8-16 8zM760 190q-8 0-10-2-118-60-238-60-130 0-238 60-10 6-20 0t-10-18q0-14 10-20 116-64 258-64 130 0 258 64 18 10 8 28-8 12-18 12z"
                ></path>
              </svg>
            </div>
            <h6 class="home-text36 TextXL">42</h6>
            <span class="home-text37">
              在浩瀚的原子和比特世界，寻找万物之源，穿越时空，论道问禅。
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="home-us1">
      <div class="home-container10">
        <div class="home-container11">
          <h6 class="home-text38">organization</h6>
          <h3 class="home-text39">组织成员</h3>
          <span class="home-text40">
            逍遥岛目前已经汇聚了包括百度、阿里、喜马拉雅、NUSKIN、珀莱雅等大厂及传统行业的人。
          </span>
        </div>
        <div class="home-container12">
          <div class="home-container13">
            <div class="home-container14">
              <div class="home-container15">
                <div class="home-cards-container1">
                  <img
                    alt="image"
                    src="/yingyi_pose_gesturetaking_picturesmountain_climbingbig_backpack_6573e7c8-13bb-4eef-8734-8727eca2a8ed%202-200w.jpeg"
                    class="home-image1"
                  />
                </div>
                <div class="home-cards-container2">
                  <img
                    alt="image"
                    src="/yingyi_pose_gesturemountain_climbingbig_orange_hiking_backpackl_4c703f63-bc1c-425e-97c1-0e4943cddb01-200w.jpeg"
                    class="home-image2"
                  />
                </div>
                <div class="home-cards-container3">
                  <img
                    alt="image"
                    src="/yingyi_pose_gestureboyhalf_bodysmile_cute_cartoon_3d_high_quali_b0e525ec-b995-48fe-9135-31ee93fd588e-200w.jpg"
                    class="home-image3"
                  />
                </div>
                <div class="home-cards-container4">
                  <img
                    alt="image"
                    src="/c92fc79ecdf21868a7e3ccab35c01512-200h.jpeg"
                    class="home-image4"
                  />
                </div>
                <div class="home-cards-container5">
                  <img
                    alt="image"
                    src="/bf2c6fb52cf584a687940eafa3018d31-200h.jpeg"
                    class="home-image5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: '逍遥岛',
    meta: [
      {
        property: 'og:title',
        content: '逍遥岛',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}
.home-header {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: center;
}
.home-heading {
  top: 60px;
  left: 40px;
  color: #505050;
  margin: 0%;
  position: absolute;
  font-size: 2rem;
  text-align: center;
}
.home-text {
  line-height: 1.25;
}
.home-text01 {
  line-height: 1.25;
}
.home-text02 {
  line-height: 1.25;
}
.home-text03 {
  line-height: 1.25;
}
.home-text04 {
  line-height: 1.25;
}
.home-text05 {
  line-height: 1.25;
}
.home-text06 {
  line-height: 1.25;
}
.home-text07 {
  line-height: 1.25;
}
.home-text08 {
  line-height: 1.25;
}
.home-text09 {
  line-height: 1.25;
}
.home-text10 {
  line-height: 1.25;
}
.home-container01 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 750px;
  display: flex;
  opacity: 0.75;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-pimary-300);
}
.home-image {
  width: 100%;
  height: 750px;
  align-self: flex-start;
  object-fit: cover;
}
.home-container02 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 750px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text12 {
  color: rgb(255, 255, 255);
  width: auto;
  z-index: 100;
  font-size: 2.5em;
  align-self: flex-start;
  font-style: normal;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.25;
  margin-left: 10%;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
}
.home-text17 {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  align-self: flex-start;
  margin-left: 10%;
}
.home-link {
  transition: 0.3s;
  text-transform: uppercase;
  text-decoration: none;
  background-color: var(--dl-color-secondary-300);
}
.home-link:hover {
  background-color: #ae26d6;
}
.home-container03 {
  top: 0px;
  flex: 0 0 auto;
  right: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-link1 {
  display: contents;
}
.home-discord-white {
  top: 0px;
  right: 80px;
  width: 22px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  transition: 0.3s;
  text-decoration: none;
}

.home-link2 {
  display: contents;
}
.home-github-white {
  width: 20px;
  align-self: center;
  object-fit: cover;
  transition: 0.3s;
  margin-right: 40px;
  text-decoration: none;
}

.home-us {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #ececec;
}
.home-container04 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  flex-direction: column;
}
.home-container05 {
  width: 75%;
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-text18 {
  color: var(--dl-color-pimary-500);
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.home-text19 {
  color: var(--dl-color-secondary-400);
  font-size: 1.4rem;
  text-align: center;
}
.home-text20 {
  color: var(--dl-color-secondary-700);
  font-size: 0.9rem;
  text-align: center;
  font-family: Arial;
  margin-bottom: 0.25rem;
}
.home-text22 {
  color: var(--dl-color-pimary-300);
}
.home-text24 {
  color: var(--dl-color-pimary-300);
}
.home-container06 {
  width: 75%;
  display: flex;
  margin-top: 100px;
  align-items: center;
  padding-top: var(--dl-space-space-triplequarter);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-text27 {
  color: var(--dl-color-pimary-500);
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.home-text28 {
  color: var(--dl-color-secondary-400);
  font-size: 1.4rem;
  text-align: center;
}
.home-text29 {
  color: var(--dl-color-secondary-700);
  font-size: 0.9rem;
  text-align: center;
  font-family: Arial;
  margin-bottom: 0.25rem;
}
.home-cards-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-card1 {
  flex: 0 0 auto;
  width: 30%;
  margin: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container07 {
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, #f87171, #dc2626);
}
.home-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.home-text30 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: Open Sans;
}
.home-text31 {
  color: var(--dl-color-secondary-700);
  font-size: 0.9rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: Open Sans;
  line-height: 1.625rem;
}
.home-card2 {
  flex: 0 0 auto;
  width: 30%;
  margin: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container08 {
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, #38bdf8, #0284c7);
}
.home-icon2 {
  fill: #ffffff;
  width: 20px;
  height: 20px;
}
.home-text32 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: Open Sans;
}
.home-text33 {
  color: var(--dl-color-secondary-700);
  font-size: 0.9rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: Open Sans;
  line-height: 1.625rem;
}
.home-card3 {
  flex: 0 0 auto;
  width: 30%;
  margin: var(--dl-space-space-unit);
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container09 {
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, #34d399, #059669);
}
.home-icon4 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.home-text36 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: Open Sans;
}
.home-text37 {
  color: var(--dl-color-secondary-700);
  font-size: 0.9rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: Open Sans;
  line-height: 1.625rem;
}
.home-us1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: var(--dl-color-pimary-900);
}
.home-container10 {
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1320px;
  align-self: center;
  align-items: center;
  margin-left: 0%;
  margin-right: 0%;
  flex-direction: column;
}
.home-container11 {
  width: 75%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-text38 {
  color: var(--dl-color-pimary-500);
  font-size: 0.875em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.home-text39 {
  color: var(--dl-color-secondary-400);
  font-size: 1.4em;
  text-align: center;
}
.home-text40 {
  color: var(--dl-color-secondary-700);
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 0.25rem;
}
.home-container12 {
  flex: 0 0 auto;
  width: 75%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 80px;
  flex-direction: row;
  justify-content: center;
}
.home-cards-container1 {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.home-image1 {
  width: 70px;
  height: 70px;
  margin: 10px;
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-cards-container2 {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.home-image2 {
  width: 70px;
  height: 70px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-cards-container3 {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.home-image3 {
  width: 70px;
  height: 70px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-cards-container4 {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.home-image4 {
  width: 70px;
  height: 70px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-cards-container5 {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.home-image5 {
  width: 70px;
  height: 70px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
@media(max-width: 1200px) {
  .home-text12 {
    color: rgb(255, 255, 255);
  }
  .home-link {
    text-transform: uppercase;
    text-decoration: none;
    background-color: var(--dl-color-secondary-300);
  }
  .home-text20 {
    color: var(--dl-color-secondary-700);
    font-size: 0.9rem;
    align-self: flex-start;
    text-align: left;
    font-family: Arial;
  }
  .home-text29 {
    color: var(--dl-color-secondary-700);
  }
  .home-text31 {
    color: var(--dl-color-secondary-700);
    font-size: 0.9rem;
    font-family: Open Sans;
    line-height: 1.625rem;
  }
  .home-text33 {
    color: var(--dl-color-secondary-700);
    font-size: 0.9rem;
    font-family: Open Sans;
    line-height: 1.625rem;
  }
  .home-text35 {
    color: var(--dl-color-secondary-700);
    font-family: Open Sans;
    line-height: 1.625rem;
  }
  .home-text37 {
    color: var(--dl-color-secondary-700);
    font-size: 0.9rem;
    font-family: Open Sans;
    line-height: 1.625rem;
  }
  .home-text38 {
    color: var(--dl-color-pimary-500);
    font-size: 0.875em;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .home-text39 {
    color: var(--dl-color-secondary-400);
  }
  .home-text40 {
    color: var(--dl-color-secondary-700);
    font-size: 0.9rem;
  }
  .home-container14 {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-container15 {
    height: auto;
    position: relative;
  }
  .home-image2 {
    width: 70px;
    height: 70px;
    margin: 10px;
    border-radius: var(--dl-radius-radius-round);
  }
  .home-image4 {
    width: 70px;
    height: 70px;
    margin: 10px;
    border-radius: var(--dl-radius-radius-round);
  }
  .home-image5 {
    width: 70px;
    height: 70px;
    margin: 10px;
    border-radius: var(--dl-radius-radius-round);
  }
}
@media(max-width: 991px) {
  .home-us {
    height: auto;
  }
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-card1 {
    width: 90%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-card2 {
    width: 90%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-card3 {
    width: 90%;
  }
  .home-container14 {
    align-self: flex-start;
    align-items: center;
    justify-content: center;
  }
  .home-container15 {
    height: auto;
    align-self: flex-start;
    justify-content: center;
  }
  .home-cards-container1 {
    height: auto;
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  .home-image1 {
    width: 70px;
    height: 70px;
    margin: 10px;
  }
  .home-cards-container2 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .home-cards-container3 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .home-cards-container4 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .home-cards-container5 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .home-text12 {
    margin-right: 10%;
  }
  .home-us {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-container05 {
    width: 100%;
  }
  .home-container06 {
    width: 100%;
  }
  .home-us1 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-container11 {
    width: 100%;
  }
  .home-container12 {
    width: 100%;
    margin-left: 20%;
    margin-right: 20%;
  }
  .home-container13 {
    width: 100%;
  }
  .home-container14 {
    position: relative;
  }
  .home-container15 {
    flex: 1;
    width: 80%;
    height: auto;
    flex-wrap: wrap;
    margin-left: 10%;
    margin-right: 10%;
    flex-direction: row;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .home-heading {
    top: 5%;
    left: 5%;
  }
  .home-text20 {
    color: var(--dl-color-secondary-700);
    font-size: 0.9rem;
    font-family: Arial;
  }
  .home-card1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text31 {
    text-align: left;
  }
  .home-card2 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text33 {
    text-align: left;
  }
  .home-text34 {
    text-align: left;
  }
  .home-text37 {
    text-align: left;
  }
  .home-container15 {
    width: 90%;
    align-self: center;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
  }
}
</style>
