<template>
  <div class="footer-container">
    <div class="footer-container01">
      <div class="footer-container02">
        <h1 class="footer-text TextXL"><span>Let&apos;s keep in touch</span></h1>
        <span class="footer-text02">
          Find out on any of these platforms, we respond 1-2 business days.
        </span>
        <div class="footer-container03">
          <div class="footer-container04">
            <svg viewBox="0 0 950.8571428571428 1024" class="footer-icon">
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path>
            </svg>
          </div>
          <div class="footer-container05">
            <svg viewBox="0 0 877.7142857142857 1024" class="footer-icon2">
              <path
                d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
              ></path>
            </svg>
          </div>
          <div class="footer-container06">
            <svg viewBox="0 0 1024 1024" class="footer-icon4">
              <path
                d="M928 0h-832c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h416v-448h-128v-128h128v-64c0-105.8 86.2-192 192-192h128v128h-128c-35.2 0-64 28.8-64 64v64h192l-32 128h-160v448h288c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96z"
              ></path>
            </svg>
          </div>
          <div class="footer-container07">
            <svg viewBox="0 0 877.7142857142857 1024" class="footer-icon6">
              <path
                d="M438.857 73.143c242.286 0 438.857 196.571 438.857 438.857 0 193.714-125.714 358.286-300 416.571-22.286 4-30.286-9.714-30.286-21.143 0-14.286 0.571-61.714 0.571-120.571 0-41.143-13.714-67.429-29.714-81.143 97.714-10.857 200.571-48 200.571-216.571 0-48-17.143-86.857-45.143-117.714 4.571-11.429 19.429-56-4.571-116.571-36.571-11.429-120.571 45.143-120.571 45.143-34.857-9.714-72.571-14.857-109.714-14.857s-74.857 5.143-109.714 14.857c0 0-84-56.571-120.571-45.143-24 60.571-9.143 105.143-4.571 116.571-28 30.857-45.143 69.714-45.143 117.714 0 168 102.286 205.714 200 216.571-12.571 11.429-24 30.857-28 58.857-25.143 11.429-89.143 30.857-127.429-36.571-24-41.714-67.429-45.143-67.429-45.143-42.857-0.571-2.857 26.857-2.857 26.857 28.571 13.143 48.571 64 48.571 64 25.714 78.286 148 52 148 52 0 36.571 0.571 70.857 0.571 81.714 0 11.429-8 25.143-30.286 21.143-174.286-58.286-300-222.857-300-416.571 0-242.286 196.571-438.857 438.857-438.857zM166.286 703.429c1.143-2.286-0.571-5.143-4-6.857-3.429-1.143-6.286-0.571-7.429 1.143-1.143 2.286 0.571 5.143 4 6.857 2.857 1.714 6.286 1.143 7.429-1.143zM184 722.857c2.286-1.714 1.714-5.714-1.143-9.143-2.857-2.857-6.857-4-9.143-1.714-2.286 1.714-1.714 5.714 1.143 9.143 2.857 2.857 6.857 4 9.143 1.714zM201.143 748.571c2.857-2.286 2.857-6.857 0-10.857-2.286-4-6.857-5.714-9.714-3.429-2.857 1.714-2.857 6.286 0 10.286s7.429 5.714 9.714 4zM225.143 772.571c2.286-2.286 1.143-7.429-2.286-10.857-4-4-9.143-4.571-11.429-1.714-2.857 2.286-1.714 7.429 2.286 10.857 4 4 9.143 4.571 11.429 1.714zM257.714 786.857c1.143-3.429-2.286-7.429-7.429-9.143-4.571-1.143-9.714 0.571-10.857 4s2.286 7.429 7.429 8.571c4.571 1.714 9.714 0 10.857-3.429zM293.714 789.714c0-4-4.571-6.857-9.714-6.286-5.143 0-9.143 2.857-9.143 6.286 0 4 4 6.857 9.714 6.286 5.143 0 9.143-2.857 9.143-6.286zM326.857 784c-0.571-3.429-5.143-5.714-10.286-5.143-5.143 1.143-8.571 4.571-8 8.571 0.571 3.429 5.143 5.714 10.286 4.571s8.571-4.571 8-8z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="footer-container08">
        <div class="footer-container09">
          <span class="footer-text03 TextXS">USEFUL LINK</span>
          <span class="footer-text04 TextSM">About Us</span>
          <span class="footer-text05 TextSM">Blog</span>
          <span class="footer-text06 TextSM">Github</span>
          <span class="footer-text07 TextSM">Free Products</span>
        </div>
        <div class="footer-container10">
          <span class="footer-text08 TextXS">OTHER RESOURCES</span>
          <span class="footer-text09 TextSM">License</span>
          <span class="footer-text10 TextSM">Terms &amp; Conditions</span>
          <span class="footer-text11 TextSM">Privacy Policy</span>
          <span class="footer-text12 TextSM">Contact Us</span>
        </div>
      </div>
    </div>
    <div class="footer-container11"></div>
    <span class="footer-text13 TextSM">
      Copyright © 2021 Notus PRO React by Creative Tim.
    </span>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
}
</script>

<style scoped>
.footer-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-pimary-900);
}
.footer-container01 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container02 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-unit);
  font-weight: 600;
}
.footer-text02 {
  color: var(--dl-color-secondary-500);
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-container03 {
  display: flex;
  margin-top: var(--dl-space-space-unitandhalf);
  align-items: flex-start;
  flex-direction: row;
}
.footer-container04 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.footer-icon {
  fill: #37BCF8;
  width: 16px;
  height: 16px;
}
.footer-container05 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.footer-icon2 {
  fill: #ea4c89;
  width: 16px;
  height: 16px;
}
.footer-container06 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.footer-icon4 {
  fill: #4267B2;
  width: 16px;
  height: 16px;
}
.footer-container07 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.footer-icon6 {
  width: 16px;
  height: 16px;
}
.footer-container08 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-container09 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 6rem;
  flex-direction: column;
}
.footer-text03 {
  color: var(--dl-color-secondary-400);
  font-weight: 600;
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text04 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text05 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text06 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text07 {
  color: var(--dl-color-secondary-500);
}
.footer-container10 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text08 {
  color: var(--dl-color-secondary-400);
  font-weight: 600;
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text09 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text10 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text11 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text12 {
  color: var(--dl-color-secondary-500);
}
.footer-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: #e3e8efff;
}
.footer-text13 {
  color: var(--dl-color-secondary-500);
  align-self: center;
}
@media(max-width: 991px) {
  .footer-container01 {
    align-items: center;
    flex-direction: column;
  }
  .footer-container02 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .footer-container09 {
    align-items: center;
  }
  .footer-container10 {
    align-items: center;
  }
}
@media(max-width: 767px) {
  .footer-text02 {
    text-align: center;
  }
  .footer-container08 {
    align-items: center;
    flex-direction: column;
  }
  .footer-container09 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .footer-container10 {
    align-items: center;
  }
}
</style>
