<template>
  <div class="calling-container">
    <div class="calling-image"><div class="calling-bg"></div></div>
    <div class="calling-container01">
      <div class="calling-container02">
        <h2 class="calling-text">AGI · 创新者招募令</h2>
        <div class="calling-container03">
          <span class="calling-text01">逍遥岛</span>
        </div>
        <div class="calling-container04"></div>
        <span class="calling-text02">
          <span class="calling-text03">作为一个秉持逍遥岛「</span>
          <span class="calling-text04">DAO</span>
          <span class="calling-text05">」思想和愿景的组织，我们正在招募「</span>
          <span class="calling-text06">独立开发者</span>
          <span class="calling-text07">」和「</span>
          <span class="calling-text08">创新者</span>
          <span class="calling-text09">
            」加入我们，一起共创产品，联合产品研发，共同探索人工智能的无限可能，一起因项目获得掌控自由人生的力量，「
          </span>
          <span class="calling-text10">告别打工人生涯</span>
          <span class="calling-text11">」。</span>
          <br />
        </span>
      </div>
      <div class="calling-container05">
        <div class="calling-container06">
          <h6 class="calling-text13">
            <span>DAO</span>
            <br />
          </h6>
          <h3 class="calling-text16">逍遥岛的理念</h3>
          <span class="calling-text17">自由、平等、共创、小而美</span>
        </div>
        <div class="calling-container07">
          <h6 class="calling-text18">We hope you</h6>
          <h3 class="calling-text19">我们希望你</h3>
          <span class="calling-text20">
            <span class="calling-text21">
              -
              希望你是人工智能、计算机、通信、自动化等相关专业毕业，亦欢迎优秀的在校生。
            </span>
            <br class="calling-text22" />
            <span class="calling-text23">
              -
              希望你具备AI算法、架构、后端、前端等研发经验，不限：NLP、视觉、语音、数字人等方向。
            </span>
            <br class="calling-text24" />
            <span class="calling-text25">
              - 希望你具有对这个世界极大的好奇心，至于你是什么背景，我们并不关心。
            </span>
            <br class="calling-text26" />
            <span class="calling-text27">
              -
              希望你对当前AGI浪潮有所认知，能玩转GPT、Diffusion、LangChain等，对最新的技术趋势保持热忱。
            </span>
            <br class="calling-text28" />
            <br class="calling-text29" />
            <span class="calling-text30">
              - 希望你至少读过以下书单中的4本书：
            </span>
            <br class="calling-text31" />
            <span class="calling-text32">
              《黑客与画家》《数学之美》《代码整洁之道》《重构：改善既有代码的设计》《编写可读代码的艺术》《编码》《失控》《信息简史》《理解媒介》《哥德尔、艾舍尔、巴赫》《创新者的窘境》《老子》《庄子》《孙子兵法》《历史的起源与目标》《认识你自己》《禅与摩托车维修的艺术》《极简人类史》《基因传》《能量与文明》《生命是什么》《我的世界观》《理想国》《人类群星闪耀时》······
            </span>
            <br class="calling-text33" />
            <br class="calling-text34" />
            <span class="calling-text35">如果你说你读过更胜的书，亦可～</span>
          </span>
        </div>
        <div class="calling-container08">
          <h6 class="calling-text36">
            <span>
              Cooperation
              <span v-html="rawr5rk"></span>
            </span>
            <br />
          </h6>
          <h3 class="calling-text39">合作方式</h3>
          <span class="calling-text40">
            <span>
              远程、数字游民、旅居……在比特世界里连接为主，视项目情况，不定期线下。
            </span>
            <br />
            <span>
              我们的合作方式是项目分成，你参与到哪个项目中，你就可以获得该项目收益的一定比例。
            </span>
            <br />
            <span>
              我们会在项目开始前签署一些必要的合作协议或者规范，以保证项目成员的权益和责任。
            </span>
          </span>
        </div>
        <div class="calling-container09">
          <h6 class="calling-text46">
            <span>Contact us</span>
            <br />
          </h6>
          <h3 class="calling-text49">联系我们</h3>
          <div class="calling-container10">
            <span class="calling-text50">
              <span>
                如果你对我们感兴趣，并且符合我们的招募要求，请发送你的简历或者作品集到招募邮箱：
              </span>
              <br class="calling-text52" />
              <span>yingyiverse@gmail.com</span>
              <br />
              <span>或</span>
              <br />
              <span>扫码咨询</span>
              <br />
            </span>
            <img
              alt="image"
              src="/3881689820983-200h.png"
              class="calling-image1"
            />
          </div>
          <span class="calling-text59">
            <span>我们会尽快与你取得联系，并邀请你参加一个简单的面试交流。</span>
            <br />
            <span>如果一切顺利，你就可以成为我们团队中的一员了！</span>
          </span>
        </div>
      </div>
    </div>
    <div class="calling-us">
      <div class="calling-container11">
        <div class="calling-container12">
          <h6 class="calling-text63">waiting for you</h6>
          <h3 class="calling-text64">等你加入</h3>
          <span class="calling-text65">
            逍遥岛目前已经汇聚了包括百度、阿里、喜马拉雅、NUSKIN、珀莱雅等大厂及传统行业的人。
          </span>
        </div>
        <div class="calling-container13">
          <div class="calling-container14">
            <div class="calling-container15">
              <div class="calling-container16">
                <div class="calling-cards-container">
                  <img
                    alt="image"
                    src="/yingyi_pose_gesturetaking_picturesmountain_climbingbig_backpack_6573e7c8-13bb-4eef-8734-8727eca2a8ed%202-200w.jpeg"
                    class="calling-image2"
                  />
                </div>
                <div class="calling-cards-container1">
                  <img
                    alt="image"
                    src="/yingyi_pose_gesturemountain_climbingbig_orange_hiking_backpackl_4c703f63-bc1c-425e-97c1-0e4943cddb01-200w.jpeg"
                    class="calling-image3"
                  />
                </div>
                <div class="calling-cards-container2">
                  <img
                    alt="image"
                    src="/yingyi_pose_gestureboyhalf_bodysmile_cute_cartoon_3d_high_quali_b0e525ec-b995-48fe-9135-31ee93fd588e-200w.jpg"
                    class="calling-image4"
                  />
                </div>
                <div class="calling-cards-container3">
                  <img
                    alt="image"
                    src="/c92fc79ecdf21868a7e3ccab35c01512-200h.jpeg"
                    class="calling-image5"
                  />
                </div>
                <div class="calling-cards-container4">
                  <img
                    alt="image"
                    src="/bf2c6fb52cf584a687940eafa3018d31-200h.jpeg"
                    class="calling-image6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Calling',
  data() {
    return {
      rawr5rk: ' ',
    }
  },
  metaInfo: {
    title: 'calling - 逍遥岛',
    meta: [
      {
        property: 'og:title',
        content: 'calling - 逍遥岛',
      },
    ],
  },
}
</script>

<style scoped>
.calling-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f2f5f9ff;
}
.calling-image {
  flex: 0 0 auto;
  width: 100%;
  height: 500px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/yingyi_large_assortment_of_different_galaxies_with_a_red_star_i_f52d3f25-a5bf-4b63-a6e1-b03cbd325560-1500w.png");
}
.calling-bg {
  top: auto;
  flex: 0 0 auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 100%;
  display: flex;
  opacity: 0.5;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.calling-container01 {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  z-index: 100;
  max-width: 1320px;
  box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
  margin-top: -12rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.calling-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 100px;
  align-items: center;
  margin-bottom: 100px;
  flex-direction: column;
}
.calling-text {
  color: var(--dl-color-secondary-300);
  font-size: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  text-transform: none;
  text-decoration: none;
}
.calling-container03 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.calling-text01 {
  color: var(--dl-color-secondary-500);
  font-size: 0.87rem;
  font-style: normal;
  margin-top: 20px;
  font-weight: 400;
  line-height: 1.25;
  margin-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.calling-container04 {
  flex: 0 0 auto;
  width: 90%;
  height: 0.5px;
  display: flex;
  opacity: 0.5;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.calling-text02 {
  color: rgb(160, 167, 181);
  width: 80%;
  font-size: 1.125rem;
  text-align: center;
  font-weight: 300;
  line-height: 1.625;
  margin-bottom: var(--dl-space-space-unit);
}
.calling-text04 {
  color: var(--dl-color-pimary-300);
}
.calling-text06 {
  color: var(--dl-color-pimary-300);
}
.calling-text08 {
  color: var(--dl-color-pimary-300);
}
.calling-text10 {
  color: var(--dl-color-pimary-300);
}
.calling-container05 {
  flex: 0;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-self: center;
  margin-top: 0%;
  align-items: center;
  margin-left: 0%;
  margin-right: 0%;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 0%;
  flex-direction: column;
  background-color: var(--dl-color-pimary-300);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius75);
  border-bottom-right-radius: var(--dl-radius-radius-radius75);
}
.calling-container06 {
  width: 66%;
  display: flex;
  margin-top: 80px;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.calling-text13 {
  color: var(--dl-color-pimary-500);
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.calling-text16 {
  color: rgb(255, 255, 255);
  font-size: 1.4rem;
  text-align: center;
}
.calling-text17 {
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  text-align: left;
  margin-bottom: 0.25rem;
}
.calling-container07 {
  width: 66%;
  display: flex;
  margin-top: 80px;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.calling-text18 {
  color: var(--dl-color-pimary-500);
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.calling-text19 {
  color: rgb(255, 255, 255);
  font-size: 1.4rem;
  text-align: center;
}
.calling-text20 {
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 0.25rem;
}
.calling-text21 {
  color: #ffffff;
}
.calling-text23 {
  color: #ffffff;
}
.calling-text25 {
  color: #ffffff;
}
.calling-text27 {
  color: #ffffff;
}
.calling-text30 {
  color: #ffffff;
}
.calling-text32 {
  color: #97d7ff;
}
.calling-text35 {
  color: #ffffff;
}
.calling-container08 {
  width: 66%;
  display: flex;
  position: relative;
  align-self: center;
  margin-top: 80px;
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  justify-content: center;
}
.calling-text36 {
  color: var(--dl-color-pimary-500);
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.calling-text39 {
  color: rgb(255, 255, 255);
  font-size: 1.41rem;
  text-align: center;
}
.calling-text40 {
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  align-self: center;
  text-align: center;
  margin-bottom: 0.25rem;
}
.calling-container09 {
  width: 66%;
  display: flex;
  margin-top: 80px;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  margin-bottom: 80px;
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.calling-text46 {
  color: var(--dl-color-pimary-500);
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.calling-text49 {
  color: rgb(255, 255, 255);
  font-size: 1.41rem;
  text-align: center;
}
.calling-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.calling-text50 {
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  align-self: center;
  text-align: center;
  margin-bottom: 0.25rem;
}
.calling-image1 {
  width: 200px;
  align-self: center;
  object-fit: cover;
}
.calling-text59 {
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  align-self: center;
  margin-top: 20px;
  text-align: center;
}
.calling-us {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: var(--dl-color-pimary-900);
}
.calling-container11 {
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1320px;
  align-self: center;
  align-items: center;
  margin-left: 0%;
  margin-right: 0%;
  flex-direction: column;
}
.calling-container12 {
  width: 75%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.calling-text63 {
  color: var(--dl-color-pimary-500);
  font-size: 0.875em;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.calling-text64 {
  color: var(--dl-color-secondary-400);
  text-align: center;
}
.calling-text65 {
  color: var(--dl-color-secondary-700);
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 0.25rem;
}
.calling-container13 {
  flex: 0 0 auto;
  width: 75%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.calling-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.calling-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.calling-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 80px;
  flex-direction: row;
  justify-content: center;
}
.calling-cards-container {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.calling-image2 {
  width: 70px;
  height: 70px;
  margin: 10px;
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.calling-cards-container1 {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.calling-image3 {
  width: 70px;
  height: 70px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.calling-cards-container2 {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.calling-image4 {
  width: 70px;
  height: 70px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.calling-cards-container3 {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.calling-image5 {
  width: 70px;
  height: 70px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.calling-cards-container4 {
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: space-between;
}
.calling-image6 {
  width: 70px;
  height: 70px;
  margin: 10px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
@media(max-width: 1200px) {
  .calling-image {
    background-image: url("/yingyi_large_assortment_of_different_galaxies_with_a_red_star_i_f52d3f25-a5bf-4b63-a6e1-b03cbd325560-1500w.png");
  }
  .calling-container02 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .calling-text02 {
    color: var(--dl-color-secondary-300);
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.625;
  }
  .calling-text03 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text04 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text05 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text06 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text07 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text08 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text09 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text10 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text11 {
    color: var(--dl-color-secondary-500);
  }
  .calling-container06 {
    width: 80%;
  }
  .calling-text17 {
    color: rgb(255, 255, 255);
    text-align: left;
  }
  .calling-container07 {
    width: 80%;
  }
  .calling-text20 {
    font-size: 0.9rem;
    text-align: center;
  }
  .calling-text21 {
    color: #ffffff;
  }
  .calling-text22 {
    color: #ffffff;
  }
  .calling-text23 {
    color: #ffffff;
  }
  .calling-text24 {
    color: #ffffff;
  }
  .calling-text25 {
    color: #ffffff;
  }
  .calling-text26 {
    color: #ffffff;
  }
  .calling-text27 {
    color: #ffffff;
  }
  .calling-text28 {
    color: #ffffff;
  }
  .calling-text29 {
    color: #ffffff;
  }
  .calling-text30 {
    color: #ffffff;
  }
  .calling-text31 {
    color: #ffffff;
  }
  .calling-text32 {
    color: #97d7ff;
  }
  .calling-text33 {
    color: #ffffff;
  }
  .calling-text34 {
    color: #ffffff;
  }
  .calling-text35 {
    color: #ffffff;
  }
  .calling-container08 {
    width: 80%;
  }
  .calling-text36 {
    color: var(--dl-color-pimary-500);
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .calling-text40 {
    color: rgb(255, 255, 255);
    align-self: center;
    text-align: center;
  }
  .calling-container09 {
    width: 80%;
  }
  .calling-text50 {
    color: rgb(255, 255, 255);
    font-size: 0.9rem;
    align-self: center;
    text-align: center;
  }
  .calling-text52 {
    text-decoration: underline;
  }
  .calling-text65 {
    color: var(--dl-color-secondary-700);
    font-size: 0.9rem;
  }
  .calling-container15 {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .calling-container16 {
    height: auto;
    position: relative;
  }
  .calling-image3 {
    width: 70px;
    height: 70px;
    margin: 10px;
    border-radius: var(--dl-radius-radius-round);
  }
  .calling-image5 {
    width: 70px;
    height: 70px;
    margin: 10px;
    border-radius: var(--dl-radius-radius-round);
  }
  .calling-image6 {
    width: 70px;
    height: 70px;
    margin: 10px;
    border-radius: var(--dl-radius-radius-round);
  }
}
@media(max-width: 991px) {
  .calling-text02 {
    color: var(--dl-color-secondary-300);
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.625;
  }
  .calling-text03 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text04 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text05 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text06 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text07 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text08 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text09 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text10 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text11 {
    color: var(--dl-color-secondary-500);
  }
  .calling-container07 {
    width: 80%;
  }
  .calling-container08 {
    width: 80%;
  }
  .calling-container09 {
    width: 80%;
  }
  .calling-container15 {
    align-self: flex-start;
    align-items: center;
    justify-content: center;
  }
  .calling-container16 {
    height: auto;
    align-self: flex-start;
    justify-content: center;
  }
  .calling-cards-container {
    height: auto;
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  .calling-image2 {
    width: 70px;
    height: 70px;
    margin: 10px;
  }
  .calling-cards-container1 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .calling-cards-container2 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .calling-cards-container3 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
  .calling-cards-container4 {
    height: auto;
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .calling-container06 {
    width: 100%;
  }
  .calling-container07 {
    width: 100%;
  }
  .calling-text20 {
    font-size: 0.9rem;
  }
  .calling-text21 {
    color: #ffffff;
  }
  .calling-text22 {
    color: #ffffff;
  }
  .calling-text23 {
    color: #ffffff;
  }
  .calling-text24 {
    color: #ffffff;
  }
  .calling-text25 {
    color: #ffffff;
  }
  .calling-text26 {
    color: #ffffff;
  }
  .calling-text27 {
    color: #ffffff;
  }
  .calling-text28 {
    color: #ffffff;
  }
  .calling-text29 {
    color: #ffffff;
  }
  .calling-text30 {
    color: #ffffff;
  }
  .calling-text31 {
    color: #ffffff;
  }
  .calling-text32 {
    color: #97d7ff;
  }
  .calling-text33 {
    color: #ffffff;
  }
  .calling-text34 {
    color: #ffffff;
  }
  .calling-text35 {
    color: #ffffff;
  }
  .calling-container08 {
    width: 100%;
  }
  .calling-container09 {
    width: 100%;
  }
  .calling-us {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .calling-container12 {
    width: 100%;
  }
  .calling-container13 {
    width: 100%;
    margin-left: 20%;
    margin-right: 20%;
  }
  .calling-container14 {
    width: 100%;
  }
  .calling-container15 {
    position: relative;
  }
  .calling-container16 {
    flex: 1;
    width: 80%;
    height: auto;
    flex-wrap: wrap;
    margin-left: 10%;
    margin-right: 10%;
    flex-direction: row;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .calling-text02 {
    color: rgb(160, 167, 181);
    font-size: 1rem;
    text-align: center;
    font-weight: 300;
    line-height: 1.625;
  }
  .calling-text03 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text04 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text05 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text06 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text07 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text08 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text09 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text10 {
    color: var(--dl-color-pimary-300);
  }
  .calling-text11 {
    color: var(--dl-color-secondary-500);
  }
  .calling-text17 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .calling-text20 {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  .calling-container08 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .calling-text40 {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .calling-text50 {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  .calling-container16 {
    width: 90%;
    align-self: center;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
  }
}
</style>
