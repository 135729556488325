var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calling1-container"},[_vm._m(0),_c('div',{staticClass:"calling1-container01"},[_vm._m(1),_c('div',{staticClass:"calling1-container05"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"calling1-container08"},[_c('h6',{staticClass:"calling1-text30"},[_c('span',[_vm._v(" Cooperation "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.rawytun)}})]),_c('br')]),_c('h3',{staticClass:"calling1-text33"},[_vm._v("合作方式")]),_vm._m(4)]),_vm._m(5)])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calling1-image"},[_c('div',{staticClass:"calling1-bg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calling1-container02"},[_c('h3',{staticClass:"calling1-text Text2XL"},[_vm._v("AGI创新者招募令")]),_c('div',{staticClass:"calling1-container03"},[_c('span',{staticClass:"calling1-text01 TextSM"},[_vm._v("WAITING FOR YOU")])]),_c('div',{staticClass:"calling1-container04"}),_c('span',{staticClass:"calling1-text02"},[_c('span',{staticClass:"calling1-text03"},[_vm._v(" 作为一个秉持DAO思想的去中心化组织，我们正在招募更多的独立开发者和创新者加入我们，产品共创，联合研发，一起探索人工智能的无限可能，希望我们一起因项目获得掌控自由人生的力量，「 ")]),_c('span',{staticClass:"calling1-text04"},[_vm._v("告别打工人生涯")]),_c('span',{staticClass:"calling1-text05"},[_vm._v("」。")]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calling1-container06"},[_c('h6',{staticClass:"calling1-text07"},[_c('span',[_vm._v("DAO")]),_c('br')]),_c('h3',{staticClass:"calling1-text10"},[_vm._v("我们的理念")]),_c('span',{staticClass:"calling1-text11"},[_vm._v("共创、平等、小而美")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calling1-container07"},[_c('h6',{staticClass:"calling1-text12"},[_vm._v("We hope you")]),_c('h3',{staticClass:"calling1-text13"},[_vm._v("我们希望你")]),_c('span',{staticClass:"calling1-text14"},[_c('span',{staticClass:"calling1-text15"},[_vm._v(" - 希望你最好是人工智能、计算机、通信、自动化等相关专业毕业，亦欢迎优秀的在校生。 ")]),_c('br',{staticClass:"calling1-text16"}),_c('span',{staticClass:"calling1-text17"},[_vm._v(" - 希望你具备人工智能算法、架构、后端、前端等研发经验，不限：NLP、视觉、语音、数字人等方向。 ")]),_c('br',{staticClass:"calling1-text18"}),_c('span',{staticClass:"calling1-text19"},[_vm._v(" - 希望你具有对这个世界极大的好奇心，至于你是什么背景，我们并不关心。 ")]),_c('br',{staticClass:"calling1-text20"}),_c('span',{staticClass:"calling1-text21"},[_vm._v(" - 希望你对当前大模型浪潮有所认知，能玩转GPT、Diffusion等，对最新的技术趋势保持热忱。 ")]),_c('br',{staticClass:"calling1-text22"}),_c('br',{staticClass:"calling1-text23"}),_c('span',{staticClass:"calling1-text24"},[_vm._v(" - 希望你至少读过以下书单中的4本书： ")]),_c('br',{staticClass:"calling1-text25"}),_c('span',{staticClass:"calling1-text26"},[_vm._v(" 《黑客与画家》《数学之美》《代码整洁之道》《重构：改善既有代码的设计》《编写可读代码的艺术》《编码》《失控》《信息简史》《理解媒介》《哥德尔、艾舍尔、巴赫》《创新者的窘境》《老子》《庄子》《孙子兵法》《我的世界观》《人类群星闪耀时》······ ")]),_c('br',{staticClass:"calling1-text27"}),_c('br',{staticClass:"calling1-text28"}),_c('span',{staticClass:"calling1-text29"},[_vm._v("如果你说你读过更胜的书，亦可～")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"calling1-text34"},[_c('span',[_vm._v(" 远程、数字游民、旅居……在比特世界里连接为主，视项目情况，不定期线下。 ")]),_c('br'),_c('span',[_vm._v(" 我们的合作方式是项目分成，你参与到哪个项目中，你就可以获得该项目收益的一定比例。 ")]),_c('br'),_c('span',[_vm._v(" 我们会在项目开始前签署一些必要的合作协议或者规范，以保证项目成员的权益和责任。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calling1-container09"},[_c('h6',{staticClass:"calling1-text40"},[_c('span',[_vm._v("Contact us")]),_c('br')]),_c('h3',{staticClass:"calling1-text43"},[_vm._v("联系我们")]),_c('span',{staticClass:"calling1-text44"},[_c('span',[_vm._v(" 如果你对我们感兴趣，并且符合我们的招募要求，请发送你的简历或者作品集到招募邮箱： ")]),_c('br',{staticClass:"calling1-text46"}),_c('span',[_vm._v("yingyiverse@gmail.com")]),_c('br'),_c('span',[_vm._v("我们会尽快与你取得联系，并邀请你参加一个简单的面试交流。")]),_c('br'),_c('span',[_vm._v("如果一切顺利，你就可以成为我们团队中的一员了！")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calling1-us"},[_c('div',{staticClass:"calling1-container10"},[_c('div',{staticClass:"calling1-container11"},[_c('h6',{staticClass:"calling1-text52"},[_vm._v("waiting for you")]),_c('h3',{staticClass:"calling1-text53"},[_vm._v("等你加入")]),_c('span',{staticClass:"calling1-text54"},[_vm._v(" 逍遥岛目前已经汇聚了包括百度、阿里、喜马拉雅、NUSKIN、珀莱雅等大厂及传统行业的人。 ")])]),_c('div',{staticClass:"calling1-container12"},[_c('div',{staticClass:"calling1-container13"},[_c('div',{staticClass:"calling1-container14"},[_c('div',{staticClass:"calling1-container15"},[_c('div',{staticClass:"calling1-cards-container"},[_c('img',{staticClass:"calling1-image1",attrs:{"alt":"image","src":"/yingyi_pose_gesturetaking_picturesmountain_climbingbig_backpack_6573e7c8-13bb-4eef-8734-8727eca2a8ed%202-200w.jpeg"}})]),_c('div',{staticClass:"calling1-cards-container1"},[_c('img',{staticClass:"calling1-image2",attrs:{"alt":"image","src":"/yingyi_pose_gesturemountain_climbingbig_orange_hiking_backpackl_4c703f63-bc1c-425e-97c1-0e4943cddb01-200w.jpeg"}})]),_c('div',{staticClass:"calling1-cards-container2"},[_c('img',{staticClass:"calling1-image3",attrs:{"alt":"image","src":"/4248e2e8a6ce46094e25be726f39302c-200h.jpeg"}})]),_c('div',{staticClass:"calling1-cards-container3"},[_c('img',{staticClass:"calling1-image4",attrs:{"alt":"image","src":"/c92fc79ecdf21868a7e3ccab35c01512-200h.jpeg"}})]),_c('div',{staticClass:"calling1-cards-container4"},[_c('img',{staticClass:"calling1-image5",attrs:{"alt":"image","src":"/bf2c6fb52cf584a687940eafa3018d31-200h.jpeg"}})])])])])])])])
}]

export { render, staticRenderFns }