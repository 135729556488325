import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Calling from './views/calling'
import Home from './views/home'
import BlogPost from './views/blog-post'
import Calling1 from './views/calling1'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'calling',
      path: '/calling',
      component: Calling,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'BlogPost',
      path: '/blog-post',
      component: BlogPost,
    },
    {
      name: 'calling1',
      path: '/calling1',
      component: Calling1,
    },
  ],
})
